import {Component} from '@angular/core'

@Component({
  selector: 'spb-start-text',
  templateUrl: './start-text.component.html',
  styleUrl: './start-text.component.scss'
})
export class StartTextComponent {

}
