import {Component, OnInit} from '@angular/core'
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import {environment} from '../../environments/environment'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  url: string = environment.sparguideUrl
  safeUrl!: SafeResourceUrl

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }

}
